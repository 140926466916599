<template>
  <v-container id="data-tables-view" fluid tag="section">
    <v-col class="text-right">
      <app-btn @click="openDialog()">
        <v-icon>mdi-account-plus-outline</v-icon> Add New Invoice
      </app-btn>
    </v-col>
    <material-card
      icon="mdi-account-group"
      icon-small
      color="primary"
      title="Invoices"
    >
      <v-card-text>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          class="ml-auto"
          hide-details
          label="Search records"
          single-line
          style="max-width: 250px"
        />

        <v-divider class="mt-3" />

        <v-data-table
          :headers="headers"
          :items="invoices"
          :search.sync="search"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isDescending"
          :footer-props="{
            showFirstLastPage: true,
          }"
        >
          <template v-slot:top>
            <v-dialog v-model="dialog" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-form ref="InvoiceForm">
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.invoiceDate"
                            label="Invoice Date"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.reference"
                            label="Reference"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.toatalCost"
                            label="Total Cost"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-autocomplete
                            v-model="editedItem.supplierId"
                            :items="suppliers"
                            item-text="supplierName"
                            item-value="supplierId"
                            label="Supplier"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline">
                  Are you sure you want to delete this item?
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="closeDelete">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                    OK
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon medium color="info" class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon medium color="error" @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
          <!-- <template v-slot:[`item.supplier`]="{ item }">
            <div
              v-if="
                suppliers != null && suppliers.length > 0 && item.supplierId
              "
            >
              {{
                suppliers.find((x) => x.supplierId === item.supplierId)
                  .supplierName
              }}
            </div>
            <div v-else-if="item.supplierId > 0">Not Found</div>
            <div v-else>--</div>
          </template> -->
          <template v-slot:[`item.invoiceDate`]="{ item }">
            <div>{{ formatTime(item.invoiceDate) }}</div>
          </template>
        </v-data-table>
      </v-card-text>
    </material-card>
  </v-container>
</template>
<script>
import * as moment from "moment/moment";

export default {
  name: "DataTablesView",
  data: () => ({
    rules: {
      required: (value) => !!value || "Required.",
    },
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Reference",
        align: "start",
        value: "reference",
      },
      { text: "Supplier", value: "supplier.supplierName" },
      { text: "Total Cost", value: "toatalCost" },
      { text: "Invoice Date", value: "invoiceDate" },
      { text: "Actions", align: "end", value: "actions", sortable: false },
    ],
    sortBy: "materialStockInvoiceId",
    isDescending: true,
    invoices: [],
    suppliers: [],
    editedIndex: -1,
    editedItem: {
      invoiceDate: "",
      toatalCost: "",
      reference: "",
      supplierId: "",
    },
    defaultItem: {
      invoiceDate: "",
      toatalCost: "",
      reference: "",
      supplierId: "",
    },
    search: undefined,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add New Invoice" : "Edit Invoice";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.$http.get("/MaterialStockInvoice").then((response) => {
        this.invoices = response.data;
      });
      this.$http.get("/Supplier").then((response) => {
        this.suppliers = response.data;
      });
    },

    openDialog() {
      this.editedItem.invoiceDate = new Date();
      this.dialog = true;
    },

    editItem(item) {
      this.editedIndex = this.invoices.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.invoiceDate = new Date(
        Date.parse(this.editedItem.invoiceDate)
      );
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.invoices.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let materialIndex = this.editedIndex;
      this.$http
        .post("/MaterialStockInvoice/Trash", {
          materialStockInvoiceId: this.editedItem.materialStockInvoiceId,
        })
        .then((response) => {
          if (response.data.success) {
            this.invoices.splice(materialIndex, 1);
            this.$toast.success("Invoice deleted successfully.", "Success", {
              position: "topRight",
            });
          } else {
            this.$toast.error(
              "Something went wrong while deleting the invoice.",
              "Error",
              {
                position: "topRight",
              }
            );
          }
        });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.$refs.InvoiceForm.validate()) {
        let materialIndex = this.editedIndex;
        if (materialIndex > -1) {
          this.$http
            .patch("/MaterialStockInvoice/Update", this.editedItem)
            .then((response) => {
              if (response.data.success) {
                this.invoices.splice(materialIndex, 1, response.data.data);
                this.$toast.success(
                  "Invoice Updated successfully.",
                  "Success",
                  {
                    position: "topRight",
                  }
                );
              } else {
                this.$toast.error(
                  "Something went wrong while updating the invoice.",
                  "Error",
                  {
                    position: "topRight",
                  }
                );
              }
            });
        } else {
          this.$http
            .post("/MaterialStockInvoice/Create", this.editedItem)
            .then((response) => {
              if (response.data.isSuccess) {
                this.invoices.unshift(response.data.outputObject);
                this.$toast.success("Invoice added successfully.", "Success", {
                  position: "topRight",
                });
              } else {
                this.$toast.error(
                  "Something went wrong while adding invoice.",
                  "Error",
                  {
                    position: "topRight",
                  }
                );
              }
            });
        }
        this.close();
      }
    },
    formatTime(dateTime) {
      return moment(dateTime).format("MMMM Do YYYY, h:mm:ss a");
    },
  },
};
</script>
